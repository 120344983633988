<template>
  <div>
    <b-card>
      <b-row>
        <b-col class="d-flex text-center align-items-center">
          <b-button variant="primary" @click="onCreateButtonClicked">
            Crea un nou allotjament
          </b-button>
          <span class="mx-2">o</span>
          <b-button variant="primary" @click="onImportButtonClicked">
            Importa un allotjament
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BButton } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
  },
  methods: {
    onCreateButtonClicked() {
      this.$router.push({
        name: "foravila-new-accommodation",
      });
    },
    onImportButtonClicked() {
      this.$router.push({
        name: "foravila-import-accommodation",
      });
    },
  },
};
</script>
